import {
	EOrderStatus,
	IBranch,
	ICustomer,
	IOrder,
	IPaymentListItemResponse,
	IProduct,
	IRefund,
	IVendor,
} from 'types/api';
import { Nullable } from 'types/common';
import { Dayjs } from 'dayjs';

export enum ECustomerSupportRequestType {
	ORDER_CANCELLATION = 'order_cancellation',
	DELAYED_ORDER_CANCELLATION_COMPENSATION = 'delayed_order_cancellation_compensation',
	DELAYED_ORDER_COMPENSATION = 'delayed_order_compensation',
	MISSING_ITEMS_COMPENSATION = 'missing_items_compensation',
	DAMAGED_ITEMS_COMPENSATION = 'damaged_items_compensation',
	WRONG_ORDER_OR_ITEMS_COMPENSATION = 'wrong_order_or_items_compensation',
	FOOD_QUALITY_COMPENSATION = 'food_quality_compensation',
	CHANGE_DELIVERY_ADDRESS = 'change_delivery_address',
}

export enum ECustomerSupportRequestStatus {
	PENDING = 'pending',
	APPROVED = 'approved',
	REJECTED = 'rejected',
}

interface ICustomerSupportRequestActions {
	can_approve: boolean;
	can_reject: boolean;
}

interface ICustomerSupportRequestInfo {
	request_date: Dayjs;
	amount: number;
	currency_code: string;
	request_reason: string;

	type: ECustomerSupportRequestType;
	request_type_description: string;

	requested_payment_method: string;
	status: ECustomerSupportRequestStatus;

	rejection_reason?: string;
	rejection_by?: string;
	rejection_date?: Dayjs;

	approved_reason?: string;
	approved_by?: string;
	approved_date?: Dayjs;
}

export interface ICustomerSupportRequest {
	id: number;
	actions: ICustomerSupportRequestActions;
	customer: {
		id: ICustomer['id'];
		name: string;
	};

	delivery_address?: string;

	info: ICustomerSupportRequestInfo;
	order: {
		id: IOrder['id'];
		code: IOrder['info']['code'];
		status: EOrderStatus; // not used -> remove
	};
	products: Nullable<ICustomerSupportRequestItem[]>;
	store: {
		id: IBranch['id'];
		name: IBranch['info']['name'];
		vendor_id: IVendor['id'];
		contact_number: IBranch['contact_info']['contact_number'];
	};
	refund?: {
		id: IRefund['id'];
		refunded_amount: number;
		cost_to_vendor: number;
		cost_to_platform: number;
		payment_method: string;
		status: IRefund['info']['status'];
	};
}

export interface ICustomerSupportRequestListItemResponse {
	id: ICustomerSupportRequest['id'];
	info: {
		requested_payment_method: ICustomerSupportRequest['info']['requested_payment_method'];
		request_type_description: ICustomerSupportRequest['info']['request_type_description'];
		request_date: ICustomerSupportRequest['info']['request_date'];
		status: ICustomerSupportRequest['info']['status'];
		type: ICustomerSupportRequest['info']['type'];
	};
	customer: ICustomerSupportRequest['customer'];
	order: ICustomerSupportRequest['order'];
}

// *Compensation Items
export interface ICustomerSupportRequestProduct {
	id: IProduct['id'];
	name: IProduct['name'];
	unit_price: number;
	quantity: number;
}

export interface ICustomerSupportRequestProductVariant {
	id: number;
	name: string;
}

export interface ICustomerSupportRequestProductOption extends ICustomerSupportRequestProduct {}

export interface ICustomerSupportRequestItem {
	product: ICustomerSupportRequestProduct;
	variant: ICustomerSupportRequestProductVariant;
	options?: ICustomerSupportRequestProductOption[];
}

export interface IRejectCustomerSupportRequestPayload {
	reason: string;
}

export interface ICustomerSupportRequestTypes {
	slug: string;
	description: string;
}

export interface IApproveCustomerSupportRequestPayload {
	comment: string;
	amount?: number;
	cost_to_platform?: number;
	cost_to_vendor?: number;
	payment_method_id?: number;
}

export interface ICustomerSupportRquestMethodItem {
	id: IPaymentListItemResponse['id'];
	title: IPaymentListItemResponse['name'];
}
