import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'store';
import { getIntegrationTypeSettings } from 'store/selectors/integrations';
import useBranch from 'hooks/useBranch';
import useVendor from 'hooks/useVendor';
import Spinner from 'components/Spinner';
import { EVendorTabsKeys } from 'pages/Vendors/VendorDetails/Tabs/types';
import IntegrationAlert from '../../SharedComponents/IntegrationAlert';
import { AssociatedMenuModal } from './AssociatedMenuModal';
import { Alert, Button, Space } from 'antd';

const BranchMenusTabContent: FC = () => {
	const navigate = useNavigate();
	const { vendorId, data: vendorData } = useVendor();
	const { branchId, data: branchData, isLoading: branchLoading, setBranchMenuId } = useBranch();

	const { t: tBranchMenu } = useTranslation('vendors', {
		keyPrefix: 'vendor_details.tabs.branches.branch_details.tabs.menus',
	});

	const [openAssociateMenuModal, setOpenAssociateMenuModal] = useState(false);

	// ! selectors
	const integrationsSettings = useSelector(getIntegrationTypeSettings(undefined, vendorData!));

	// ! handlers
	const navigateToMenu = (menuId: number) => {
		navigate(`${menuId}`, { replace: true });
	};

	const closeAssociateMenuModal = () => setOpenAssociateMenuModal(false);

	const onConfirmMenuAssociation = (newAssociatedMenuId: number) => {
		closeAssociateMenuModal();

		setBranchMenuId(newAssociatedMenuId);
		navigateToMenu(newAssociatedMenuId);
	};

	// ! effects
	useEffect(() => {
		if (!branchData?.menu_id) return;

		navigateToMenu(branchData?.menu_id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [branchData?.menu_id]);

	// ! renders
	if (!integrationsSettings || branchLoading || branchData?.menu_id) {
		return <Spinner defaultAntdSpinner />;
	}

	// Render Branch Menu
	return (
		<>
			{!integrationsSettings.isIntegration ? (
				<Alert
					showIcon
					type='warning'
					message={
						<Space size='large'>
							{tBranchMenu('messages.no_menu.warning.title')}
							<Button
								size='small'
								onClick={() => setOpenAssociateMenuModal(true)}
							>
								{tBranchMenu('actions.associate_menu')}
							</Button>
						</Space>
					}
				/>
			) : (
				<IntegrationAlert pageType={EVendorTabsKeys.MENUS} />
			)}

			<AssociatedMenuModal
				vendorId={vendorId}
				branchId={branchId}
				open={openAssociateMenuModal}
				onCloseModal={closeAssociateMenuModal}
				onAfterConfirm={onConfirmMenuAssociation}
			/>
		</>
	);
};

export default BranchMenusTabContent;
