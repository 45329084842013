import { APP_PERMISSIONS } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import { ECustomerTabsKeys } from './types';
import CustomerAddresses from './AddressesTab';
import CariUnlimitedHistoryTab from './CariUnlimitedHistoryTab';
import CompensationsTab from './CompensationsTab';
import CustomerSupportRequestsTab from './CustomerSupportRequestsTab';
import DetailsTab from './DetailsTab';
import OrdersTab from './OrdersTab';
import WalletHistoryTab from './WalletHistoryTab';
import { AimOutlined, FundOutlined, InfoOutlined, ShopOutlined, UndoOutlined } from '@ant-design/icons';

export const CUSTOMER_DETAILS_TAB_ROUTES: Record<ECustomerTabsKeys, TRouteObject> = {
	[ECustomerTabsKeys.DETAILS]: {
		path: ECustomerTabsKeys.DETAILS,
		element: <DetailsTab />,
		handle: {
			permissions: [],
			icon: <InfoOutlined />,
			showOnNav: false,
		},
	},
	[ECustomerTabsKeys.ADDRESSES]: {
		path: ECustomerTabsKeys.ADDRESSES,
		element: <CustomerAddresses />,
		handle: {
			permissions: [APP_PERMISSIONS.customer.address.view],
			icon: <AimOutlined />,
			showOnNav: false,
		},
	},
	[ECustomerTabsKeys.ORDERS]: {
		path: ECustomerTabsKeys.ORDERS,
		element: <OrdersTab />,
		handle: {
			permissions: [APP_PERMISSIONS.order.view],
			icon: <ShopOutlined />,
			showOnNav: false,
		},
	},
	[ECustomerTabsKeys.WALLET_HISTORY]: {
		path: ECustomerTabsKeys.WALLET_HISTORY,
		element: <WalletHistoryTab />,
		handle: {
			permissions: [APP_PERMISSIONS.customer.wallet.view_history],
			icon: <FundOutlined />,
			showOnNav: false,
		},
	},
	[ECustomerTabsKeys.COMPENSATION_REQUESTS]: {
		path: ECustomerTabsKeys.COMPENSATION_REQUESTS,
		element: <CompensationsTab />,
		handle: {
			permissions: [APP_PERMISSIONS.compensation_request.view],
			icon: <UndoOutlined />,
			showOnNav: false,
		},
	},
	[ECustomerTabsKeys.CARI_UNLIMITED_HISTORY]: {
		path: ECustomerTabsKeys.CARI_UNLIMITED_HISTORY,
		element: <CariUnlimitedHistoryTab />,
		handle: {
			permissions: [APP_PERMISSIONS.cari_unlimited.manage],
			icon: <FundOutlined />,
			showOnNav: false,
		},
	},
	[ECustomerTabsKeys.CUSTOMER_SUPPORT_REQUEST]: {
		path: ECustomerTabsKeys.CUSTOMER_SUPPORT_REQUEST,
		element: <CustomerSupportRequestsTab />,
		handle: {
			permissions: [APP_PERMISSIONS.customer_support_requests.view],
			icon: <UndoOutlined />,
			showOnNav: false,
		},
	},
};

export const DEFAULT_CUSTOMER_DETAILS_TAB_KEY = ECustomerTabsKeys.DETAILS;

export const CUSTOMER_TAB_ROUTES: TRouteObject[] = Object.values(CUSTOMER_DETAILS_TAB_ROUTES);
