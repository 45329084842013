import { createSelector } from '@reduxjs/toolkit';
import { IIntegrationSlice } from 'store/slices/integrations';
import { ISelector } from 'store/types';
import { IVendor } from 'types/api';
import { IFilterItem, ISelectOption, Nullable } from 'types/common';

export const getIntegrationState: ISelector<void, IIntegrationSlice['data']> = (state) => state.integrations.data;

export const getIntegrationTypeSettings = (integrationType?: Nullable<string>, vendorData?: IVendor) =>
	createSelector([getIntegrationState], (integrationState) => {
		integrationType = integrationType ?? vendorData?.info.integration_type;

		if (!integrationType || !integrationState) return null;

		return integrationState[integrationType];
	});

export const getIntegrationTypesList: ISelector<void, string[]> = (state) => {
	if (!state.integrations.data) return [];

	return Object.keys(state.integrations.data);
};

export const getIntegrationTypesListOptions = createSelector(
	[getIntegrationTypesList, getIntegrationState],
	(integrationTypesList, integrationData) => {
		if (!integrationData) return [];

		return integrationTypesList.map(
			(value): ISelectOption => ({
				label: integrationData[value].name,
				value,
			})
		);
	}
);

export const getIntegrationTypesFilterOptions = createSelector(
	[getIntegrationTypesList, getIntegrationState],
	(integrationTypesList, integrationData) => {
		if (!integrationData) return [];

		return integrationTypesList.map(
			(value): IFilterItem => ({
				text: integrationData[value].name,
				value,
			})
		);
	}
);
