// Refund Order
interface IRefundOrderInfo {
	code: string;
	status: number;
	placed_at: string;
	new_at: string;
}

interface IRefundOrderCustomer {
	id: number;
	name: string;
}

interface IRefundOrderBranch {
	id: number;
	name: string;
	timezone: string;
	vendor_id: number;
}

interface IRefundOrderPayment {
	amount_payable: number;
	type: number;
	paid_at: string;
}

interface IRefundOrderDriver {
	name?: string;
	id?: number;
}

export enum ERefundStatus {
	REFUNDED = 'refunded',
	CANCELED = 'canceled',
	REJECTED = 'rejected',
	PENDING = 'pending',
	ERROR = 'error',
}
export enum ERefundType { // PaymentMethods -> enum on backend
	WALLET = 'wallet',
	BANK_ACCOUNT = 'bank_account',
	PROMOTION = 'promotion',
}

export interface IRefundOrder {
	id: number;
	info: IRefundOrderInfo;
	customer: IRefundOrderCustomer;
	store: IRefundOrderBranch;
	payment: IRefundOrderPayment;
	driver?: IRefundOrderDriver;
}

// Refund Elements
export interface IRefundInfo {
	code: string;
	amount: number;
	currency_code: string;
	payment_method: string; // Set as a string because the value comes from myfatoorah
	comment: string;
	date: string;
	status: ERefundStatus;
	// refund cost source
	cost_to_vendor: number;
	cost_to_platform: number;
}

export interface IRefundAuthor {
	id: number;
	name: string;
}

// ! Refund
export interface IRefund {
	id: number;
	info: IRefundInfo;
	author: IRefundAuthor;
	order: IRefundOrder;
}

// * CRUD
export interface ICreateRefundData {
	order_id: number;
	amount: number;
	payment_method_id: string;
	comment: string;
	// refund cost source
	cost_to_vendor?: number;
	cost_to_platform?: number;
}

export interface ICreateRefundForm
	extends Pick<
		ICreateRefundData,
		'amount' | 'payment_method_id' | 'comment' | 'cost_to_vendor' | 'cost_to_platform'
	> {}

export interface IUpdateRefundRequestBody {
	cost_to_vendor: number;
	cost_to_platform: number;
}

export interface IAutomaticRefundPayload {
	order_id: number;
	refund_id: number;
	cost_to_vendor: number;
	cost_to_platform: number;
}
