import { lazy } from 'react';
import { APP_PERMISSIONS } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import { ESupportedCountriesIsoTwoCodes } from 'types/common';
import CITCIcon from 'components/CitcIcon';
import { BarsOutlined, CreditCardOutlined, InfoOutlined, UndoOutlined } from '@ant-design/icons';

// ! Lazy module imports

const OrderCitcTabContent = lazy(() => import('../OrderDetails/Tabs/CitcOrderTab'));
const OrderInfo = lazy(() => import('../OrderDetails/Tabs/OrderInfo/OrderInfo'));
const PaymentIntegration = lazy(() => import('../OrderDetails/Tabs/PaymentIntegration'));
const TimelineTab = lazy(() => import('../OrderDetails/Tabs/TimelineTab'));
const CustomerSupportRequestsTab = lazy(() => import('../OrderDetails/Tabs/CustomerSupportRequestsTab'));

export enum EOrderTabsKeys {
	DETAILS = 'details',
	TIMELINE = 'timeline',
	PAYMENT = 'payment',
	CUSTOMER_SUPPORT_REQUESTS = 'customer_support_requests',
	CITC = 'citc',
}

export const ORDER_DETAILS_TAB_ROUTES: Record<EOrderTabsKeys, TRouteObject> = {
	[EOrderTabsKeys.DETAILS]: {
		path: EOrderTabsKeys.DETAILS,
		element: <OrderInfo />,
		handle: {
			permissions: [],
			icon: <InfoOutlined />,
			showOnNav: false,
		},
	},
	[EOrderTabsKeys.TIMELINE]: {
		path: EOrderTabsKeys.TIMELINE,
		element: <TimelineTab />,
		handle: {
			permissions: [APP_PERMISSIONS.order.view_timeline],
			icon: <BarsOutlined />,
			showOnNav: false,
		},
	},
	[EOrderTabsKeys.PAYMENT]: {
		path: EOrderTabsKeys.PAYMENT,
		element: <PaymentIntegration />,
		handle: {
			permissions: [APP_PERMISSIONS.order.view_payment_response_from_gateway],
			icon: <CreditCardOutlined />,
			showOnNav: false,
		},
	},
	[EOrderTabsKeys.CUSTOMER_SUPPORT_REQUESTS]: {
		path: EOrderTabsKeys.CUSTOMER_SUPPORT_REQUESTS,
		element: <CustomerSupportRequestsTab />,
		handle: {
			permissions: [APP_PERMISSIONS.order.view_customer_support_requests],
			icon: <UndoOutlined />,
			showOnNav: false,
		},
	},
	[EOrderTabsKeys.CITC]: {
		path: EOrderTabsKeys.CITC,
		element: <OrderCitcTabContent />,
		handle: {
			permissions: [APP_PERMISSIONS.citc.view],
			country_white_list: [ESupportedCountriesIsoTwoCodes.SAUDI_ARABIA],
			icon: <CITCIcon />,
			showOnNav: false,
		},
	},
};

export const DEFAULT_ORDER_DETAILS_TAB_KEY = EOrderTabsKeys.DETAILS;

export const ORDER_TABS_ROUTES: TRouteObject[] = Object.values(ORDER_DETAILS_TAB_ROUTES);
