import { Dispatch, PropsWithChildren, SetStateAction, createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { throttle } from 'utils/throttle';
import { VENDOR_API } from 'configs/api';
import { EVendorStatus, IVendor } from 'types/api';
import { EChoiceOption, Nullable, TEmptyFunction } from 'types/common';
import { useNewHttpClient } from './useHttpClient';

interface IVendorContextData {
	vendorId: number;
	data: Nullable<IVendor>;
	error: any;
	isLoading: boolean;

	// handlers
	handleToggleStatus: (id: number, status: EVendorStatus, successCallback?: TEmptyFunction) => Promise<void>;
	fetchData: () => void;

	setData: Dispatch<SetStateAction<Nullable<IVendor>>>;
	setIsCariUnlimited: (payload: boolean) => void;
	getVendorIntegrationType: () => Nullable<string>;
}

const VendorContext = createContext<IVendorContextData>({} as IVendorContextData);

const useVendor = () => {
	return useContext(VendorContext);
};

const VendorProvider = ({ children }: PropsWithChildren) => {
	const { vendorId } = useParams();

	const [vendorData, setVendorData] = useState<Nullable<IVendor>>(null);

	// ! http clients
	const toggleStatusHttpClient = useNewHttpClient();
	const { error, isLoading, request: fetchRequest } = useNewHttpClient<IVendor>();

	// ! handlers
	const handleToggleStatus = (id: number, status: EVendorStatus, successCallback?: () => void) => {
		return toggleStatusHttpClient.request({
			requestConfig: VENDOR_API.update(id, { status }),
			successCallback: () => {
				successCallback?.();

				setVendorStatus(status);
			},
		});
	};

	const fetchData = throttle(() => {
		if (!vendorId || isLoading) return;

		fetchRequest({
			requestConfig: VENDOR_API.get(+vendorId),
			successCallback: (response) => {
				setVendorData(response);
			},
		});
	});

	const setIsCariUnlimitedHandle = (payload: boolean) => {
		if (!vendorData) return;

		const is_associated_with_cari_unlimited = payload ? EChoiceOption.YES : EChoiceOption.NO;
		const vendor: IVendor = { ...vendorData, is_associated_with_cari_unlimited };

		setVendorData(vendor);
	};

	const setVendorStatus = (newStatus: EVendorStatus) => {
		if (!vendorData) return;

		const vendorInfo = { ...vendorData.info, status: newStatus };

		const vendor: IVendor = {
			...vendorData,
			info: vendorInfo,
		};

		setVendorData(vendor);
	};

	const getVendorIntegrationType = (): Nullable<string> => {
		if (!vendorData) return null;

		return vendorData.info.integration_type ?? 'platform';
	};

	// ! useEffects
	useEffect(() => {
		fetchData();

		return () => {
			setVendorData(null);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [vendorId]);

	// ! render
	if (!vendorId) return null;

	const vendorContextData = {
		vendorId: +vendorId,
		data: vendorData,
		error,
		isLoading,

		// * handlers
		handleToggleStatus,
		fetchData,
		setData: setVendorData,
		setIsCariUnlimited: setIsCariUnlimitedHandle,
		getVendorIntegrationType,
	};

	return <VendorContext.Provider value={vendorContextData}>{children}</VendorContext.Provider>;
};

export default useVendor;
export { VendorProvider, useVendor };
